import { render, staticRenderFns } from "./month-manager.renderer.vue?vue&type=template&id=510b814b&scoped=true&"
import script from "./month-manager.renderer.vue?vue&type=script&lang=js&"
export * from "./month-manager.renderer.vue?vue&type=script&lang=js&"
import style0 from "./month-manager.renderer.vue?vue&type=style&index=0&id=510b814b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510b814b",
  null
  
)

export default component.exports