<template>
  <div
    :class="['month-manager-wrapper', dynamicClass]"
    v-on:dragover="onDragOver($event)"
    v-on:drop="onDrop($event)"
  >
    {{ name }}
    <button v-if="active" class="delete-button" @click="deleteManager">
      <img
        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAxOCAyMSIgZmlsbD0iIzFjNzhkZiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQ0ODFfNDY5NykiPgo8cGF0aCBkPSJNNS40MzIxNCAwLjcyNTk3N0w1LjE0Mjg2IDEuMzEyNUgxLjI4NTcxQzAuNTc0NTU0IDEuMzEyNSAwIDEuODk5MDIgMCAyLjYyNUMwIDMuMzUwOTggMC41NzQ1NTQgMy45Mzc1IDEuMjg1NzEgMy45Mzc1SDE2LjcxNDNDMTcuNDI1NCAzLjkzNzUgMTggMy4zNTA5OCAxOCAyLjYyNUMxOCAxLjg5OTAyIDE3LjQyNTQgMS4zMTI1IDE2LjcxNDMgMS4zMTI1SDEyLjg1NzFMMTIuNTY3OSAwLjcyNTk3N0MxMi4zNTA5IDAuMjc4OTA2IDExLjkwNDkgMCAxMS40MTg4IDBINi41ODEyNUM2LjA5NTA5IDAgNS42NDkxMSAwLjI3ODkwNiA1LjQzMjE0IDAuNzI1OTc3Wk0xNi43MTQzIDUuMjVIMS4yODU3MUwyLjEzNzUgMTkuMTU0M0MyLjIwMTc5IDIwLjE5MiAzLjA0NTU0IDIxIDQuMDYyMDUgMjFIMTMuOTM3OUMxNC45NTQ1IDIxIDE1Ljc5ODIgMjAuMTkyIDE1Ljg2MjUgMTkuMTU0M0wxNi43MTQzIDUuMjVaIiBmaWxsPSIjMWM3OGRmIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfNDQ4MV80Njk3Ij4KPHJlY3Qgd2lkdGg9IjE4IiBoZWlnaHQ9IjIxIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo="
        alt="Delete Manager"
      />
    </button>
  </div>
</template>

<script>
// API
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  data() {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      _id: null,
      name: null,
      active: null,
      team: null,
      year: null,
      month: null,
      dynamicClass: null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    getPreviewsTeamMember(team, month, year, data) {
      if (
        data[`${team}_${month}_${year}`] &&
        data[`${team}_${month}_${year}`]['name']
      ) {
        return data[`${team}_${month}_${year}`].name
      }
      let prevMonth = month - 1
      let prevYear = year
      if (prevMonth < 0) {
        prevMonth = 12
        prevYear = year - 1
      }
      if (prevYear < 2021) {
        return false
      }
      return this.getPreviewsTeamMember(team, prevMonth, prevYear, data)
    },
    async deleteManager() {
      await MtApi.updateClient(this.params.data._id, {
        event: 'delete_team_change',
        field: {
          _id: this.params.value._id,
          month: this.month,
          year: this.year,
          team: this.team
        }
      })
      this.params.node.setDataValue(this.params.colDef.field, {})
      this.params.api.redrawRows()
      this.init()
    },
    onDragOver(event) {
      const types = event.dataTransfer.types
      const dragSupported = types.length
      if (dragSupported) {
        event.dataTransfer.dropEffect = 'move'
      }
      event.preventDefault()
    },
    async onDrop(event) {
      const data = event.dataTransfer.getData('text/plain')
      const staff = JSON.parse(data)
      await MtApi.updateClient(this.params.data._id, {
        event: 'set_team_change',
        field: {
          _id: staff._id,
          month: this.month,
          year: this.year,
          team: this.team
        }
      })
      this.params.node.setDataValue(this.params.colDef.field, {
        _id: staff._id,
        name: staff.name
      })
      this.params.api.redrawRows()
      this.init()
    },
    extractNumbers(fieldName) {
      const parts = fieldName.split('_')
      const numbers = parts.filter((part) => !isNaN(part) && part.trim() !== '')
      return numbers.length === 2 ? { firstNumber: numbers[0], secondNumber: numbers[1] } : null
    },
    init() {
      const params = this.params
      const columnParse = this.extractNumbers(this.params.colDef.field)
      this.year = columnParse.secondNumber
      this.month = columnParse.firstNumber
      this.team = params.team
      this.name = this.params.data[this.team]?.name
      const previewTeamMember = this.getPreviewsTeamMember(
        this.team,
        this.month,
        this.year,
        this.params.data
      )
      const value = params.value
      if (value) {
        this._id = value._id
        this.name = value.name
      }
      if (value && value._id) {
        this.active = true
      }
      if (!this.active) {
        this.dynamicClass = 'some'
      }
      this.name = previewTeamMember || '------'
    }
  }
}
</script>

<style lang="scss" scoped>
.month-manager-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  &.some {
    opacity: 0.3;
  }
  &.status-inactive {
    color: #FF3D34;
    text-decoration: line-through;
  }
  .delete-button {
    background-color: transparent;
    border: none;
    img {
      height: 15px;
      transform: translate(0, -2px);
    }
  }
}
</style>
